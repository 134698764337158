import React, { ReactNode, useCallback, useState } from "react";
import { navigate } from "gatsby";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseLayout from "../../layouts/BaseLayout";
import { requestLogin } from "../../store/login/loginActions";
import { useAction } from "../../utils/hooks/useAction";
import { bindChange } from "../../utils/reactUtils";
import { DEV } from "../../config";
import { FetchError } from "../../services/utils/ApiFetchError";
import LocalStorageNote from "../../utils/polyfills/LocalStorageNote";
import { LoginResponseBody } from "../../@core/types/api/loginRouteTypes";
import { getDefaultLoginRoute } from "./useDefaultLoginRoutet";

interface LoginScreenProps {}

function LoginScreen(props: LoginScreenProps) {
  const tryLogin = useAction(requestLogin);
  const [name, setName] = useState((DEV && process.env.GATSBY_DEV_USER) || "");
  const [password, setPassword] = useState(
    (DEV && process.env.GATSBY_DEV_PASSWORD) || ""
  );
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const onSubmit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setFetching(true);
      tryLogin({ name, password })
        .then((json: LoginResponseBody) => {
          const route = getDefaultLoginRoute();
          navigate(route).then(null);
        })
        .catch((error: FetchError) => {
          setError(error.publicMessage || "an unknown error occurred");
        })
        .then(() => {
          setFetching(false);
        });
    },
    [name, password]
  );
  const submitDisabled = !name || !password || fetching;
  return (
    <BaseLayout>
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-4-desktop is-3-widescreen">
                <form action="" className="box">
                  <div className="field">
                    <label htmlFor="" className="label">
                      Name
                    </label>
                    <div className="control has-icons-left">
                      <input
                        type="text"
                        placeholder="e.g. maxmuster"
                        className="input"
                        required
                        value={name}
                        onChange={bindChange(setName)}
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Password
                    </label>
                    <div className="control has-icons-left">
                      <input
                        type="password"
                        placeholder="*******"
                        className="input"
                        required
                        value={password}
                        onChange={bindChange(setPassword)}
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      disabled={submitDisabled}
                      className="button is-success"
                      onClick={onSubmit}
                    >
                      Login
                    </button>
                  </div>
                  {error && <Error>{error}</Error>}
                </form>
                <LocalStorageNote />
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
}

const Error = ({ children }: { children: ReactNode }) => (
  <div className="notification is-danger">{children}</div>
);

export default LoginScreen;
