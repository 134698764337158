import React, { memo, useEffect, useState } from "react";
import Alert from "../../components/alert/Alert";

interface LocalStorageNoteProps {}

function LocalStorageNote(props: LocalStorageNoteProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!isLocalStorageAvailable()) setShow(true);
  }, []);
  if (!show) return null;
  return (
    <Alert type={"warning"}>
      You`&apos;re using an outdated browser that doesn`&apos;t support{" "}
      <i>localStorage</i>. Your login information won`&apos;t be saved across
      page loads.
    </Alert>
  );
}

function isLocalStorageAvailable() {
  return storageAvailable("localStorage");
}

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export default memo(LocalStorageNote);
