import { UserRole } from "@core/types/domain/user/UserRole";
import { globalStore } from "~/store/initStore";
import { userRoleSelector } from "~/store/login/loginReducer";
import { AppRoute } from "~/config/Routes";

/** returns the route that the user should be redirected to after login */
export function getDefaultLoginRoute() {
  const role = userRoleSelector(globalStore.getState());
  if (role === UserRole.Lawyer) {
    return "/app/videos";
  } else if (role === UserRole.Checker) {
    return AppRoute.Checker;
  } else if (role === UserRole.LogoDetection) {
    return AppRoute.LivestreamOverview;
  } else {
    return "/app";
  }
}
