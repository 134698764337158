import React from "react";
import "./global/bulma.scss";
import { Global } from "@emotion/react";
import { globalCss } from "./global/global.css";
import { WithChildren } from "~/types/reactTypes";

type BaseLayoutProps = WithChildren;

function BaseLayout(props: BaseLayoutProps) {
  return (
    <>
      <Global styles={globalCss} />
      {props.children}
    </>
  );
}

export default BaseLayout;
