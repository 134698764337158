import { ChangeEvent, useRef, useState } from "react";
import { getQueryParam } from "~/utils/jsUtils";

export function applyClassName(
  className: string,
  props: { className?: string }
) {
  if (!props || !props.className) {
    return className;
  } else {
    return `${className} ${props.className}`;
  }
}

export function bindChange(onValueChange: (value: string) => void) {
  return (event: ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value);
  };
}

export function createQueryDefaultResolver<T>(
  name: string,
  defaultValue: T,
  transform?: (val: string) => T
) {
  return () => getQueryParam(name, transform) || defaultValue;
}

let nextId = 1;
export function useId(): string {
  const id = useRef(nextId++);
  return `r-id-${id.current}`;
}
